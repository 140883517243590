.row > .column {
    padding: 0 8px;
    border: 2px solid red;
      padding: 10px;
  
  }
  
  .row:after {
    content: "";
    display: table;
    clear: both;
  }
  .dropdown {
    margin-bottom: 100px;

  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
  .dropdown:hover .dropdown-content {
    display: block;
    margin-top: -100px;
    margin-right: -100%;
  }
  .column {
    /*float: left;
    width: 25%;
    padding: 0 8px;
    border: 2px solid red;*/
      padding: 10px;
  }
  
  /* The Modal (background) 
  .modal {
    display: none;
    position: fixed;
    z-index: 1;
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: black;
  }*/
  
  /* Modal Content
  .modal-content {
    position: relative;
    background-color: #fefefe;
    margin: auto;
    padding: 0;
    width: 60%;
    max-width: 1200px;
  } */
  
  /* The Close Button 
  .close {
    color: white;
    position: absolute;
    top: 10px;
    right: 25px;
    font-size: 35px;
    font-weight: bold;
  }
  
  .close:hover,
  .close:focus {
    color: #999;
    text-decoration: none;
    cursor: pointer;
  }*/
  
  .mySlides {
    display: none;
  }
  
  .cursor {
    cursor: pointer;
  }
  
  /* Next & previous buttons */
  .prev,
  .next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 16px;
    margin-top: -50px;
    padding-left: 0px;
    padding-right: 0px;
    color: white;
    font-weight: bold;
    font-size: 20px;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;
    -webkit-user-select: none;
  }
  
  /* Position the "next button" to the right */
  .next {
    right: 0;
    border-radius: 3px 0 0 3px;
  }
  
  /* On hover, add a black background color with a little bit see-through */
  .prev:hover,
  .next:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }
  
  /* Number text (1/3 etc) */
  .numbertext {
    color: #f2f2f2;
    font-size: 12px;
    padding: 8px 12px;
    position: absolute;
    top: 0;
  }
  
  img {
    margin-bottom: -4px;
  }
  
  .caption-container {
    text-align: center;
    background-color: black;
    padding: 2px 16px;
    color: white;
  }
  
  .demo {
    opacity: 0.6;
  }
  
  .active,
  .demo:hover {
    opacity: 1;
  }
  
  img.hover-shadow {
    transition: 0.3s;
  }
  
  .hover-shadow:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  .imageWidth
  {
      width:100%;
      
  }
  @media screen and (max-width: 600px) {
    .imageWidth {
      
      width: 167%;
      margin-left: -65px;
    }
  }
  .txtwidth
  {
      width:50%;
  }
  @media screen and (min-width: 680px) {
    .txtwidth {
      width:100%;
    }
  }
  .service-sidebar-area .service-card {
      margin-bottom: 30px;
      background-color: #ffffff;
      -webkit-box-shadow: 0 0 20px 3px rgb(0 0 0 / 5%);
      box-shadow: 0 0 20px 3px rgb(0 0 0 / 5%);
  }
  .service-sidebar-area .service-details-title {
      background-color: #2c79ff;
      color: #ffffff;
      padding: 15px 20px;
      margin-bottom: 0;
  }
  ul {
      list-style: none;
      padding: 0;
      margin: 0;
  }
  .service-sidebar-area .service-list ul li {
      border-bottom: 1px dashed #eeeeee;
      padding: 15px 20px;
      font-weight: 500;
      
      transition: all 0.5s;
  }
  .service-sidebar-area .service-list ul li i {
      float: right;
      width: 30px;
      height: 30px;
      line-height: 30px;
      background-color: #2c79ff;
      border-radius: 50%;
      color: #ffffff;
      font-size: 20px;
      text-align: center;
      transition: all 0.5s;
      position: relative;
      top: -3px;
  }
  .bx {
      font-family: boxicons!important;
      font-weight: 400;
      font-style: normal;
      font-variant: normal;
      text-rendering: auto;
      display: inline-block;
      text-transform: none;
      speak: none;
      -webkit-font-smoothing: antialiased;
  }
  
      /* Custom style */
      .accordion-button::after {
        background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='%23333' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' d='M8 0a1 1 0 0 1 1 1v6h6a1 1 0 1 1 0 2H9v6a1 1 0 1 1-2 0V9H1a1 1 0 0 1 0-2h6V1a1 1 0 0 1 1-1z' clip-rule='evenodd'/%3e%3c/svg%3e");
        transform: scale(.7) !important;
      }
      .accordion-button:not(.collapsed)::after {
        background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='%23333' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' d='M0 8a1 1 0 0 1 1-1h14a1 1 0 1 1 0 2H1a1 1 0 0 1-1-1z' clip-rule='evenodd'/%3e%3c/svg%3e");
      }

      .App {
        font-family: sans-serif;
        text-align: center;
      }
      
      .dialog {
       
      }
      
      .image {
        width: 800px;
        height: 500;
        z-index: 1000;
      }
      .containerr{
        width: 100%;
        min-height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 50px 8%;
      }
      
      .gallery{
        display: grid;  
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 30px;
      }
      *{
        margin: 0;
        padding: 0;
        box-sizing: border-box;
      }
      .gallery img{
        width: 100%;
      }
      
      
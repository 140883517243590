.containerr{
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 8%;
}

.gallery{
  display: grid;  
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
}
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.gallery img{
  width: 100%;
}

